import React from "react";
import {
  Dialog,
  Card,
  CardContent,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import succesImage from "../../image/succes.jpg";
import CancelIcon from "@mui/icons-material/Cancel";
const styles = {
  dialog: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    position: "relative",
    width: "fit-content",
    maxWidth: "100%",
    borderRadius: "20px",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    color: "#B31312",
  },
  content: {
    textAlign: "center",
    padding: "20px",
  },
  imageContainer: {
    background: "#f5f5f5",
    borderRadius: "50%",
    width: "120px",
    height: "120px",
    padding: "10px",
    margin: "0 auto 20px",
    // Add styles for the image container
  },
  button: {
    backgroundColor: "#FF1744",
    color: "white",
    textTransform: "none",
    width: "100%",
    padding: "10px 0",
    marginTop: "20px",
  },
};

function MessageSucces({ open, onClose  }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      style={styles.dialog}
      PaperProps={{ style: { borderRadius: 20 } }}
    >
      <Card style={styles.card}>
        <IconButton style={styles.closeButton} onClick={onClose}>
          <CancelIcon />
        </IconButton>
        <CardContent style={styles.content}>
          
            <img src={succesImage} />
        
          <Typography variant="h5" component="h2" gutterBottom>
            Woohoo! 🥳
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p">
            Votre commande a été créée avec succès
          </Typography>
          <Button variant="contained" onClick={onClose} style={styles.button}>
            Continuer
          </Button>
        </CardContent>
      </Card>
    </Dialog>
  );
}

export default MessageSucces;
