import { Delete } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Card,
  CardMedia,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  EditeImage,
  GetCommand,
  GetImageParCommand,
} from "../../store/slice/CommandDahbordSlice";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import CropIcon from "@mui/icons-material/Crop";
export default function GalleryImageTraite({
  open,
  onClose,
  downloadImage,
  id,
}) {
  const disptach = useDispatch();
  const images = useSelector((state) => state.commands?.PopupData?.images);
  const imprimant = useSelector(
    (state) => state.commands?.PopupData?.imprimant
  );
  const [AllImages, setAllImage] = useState([]);
  const [openCropper, setOpenCropper] = useState(false);
  const [demonsion, setDemonsion] = useState({});
  const [src, setSrc] = useState();
  const [idImage, setIdImage] = useState();
  useEffect(() => {
    setAllImage(images);
    console.log(images);
  }, [images]);
  function editeImage(Crop) {
    setOpenCropper(false);
    const data = {
      ...Crop,
      id: idImage,
    };
    console.log(data);
    disptach(EditeImage(data)).then((result) => {
      if (EditeImage.fulfilled.match(result)) {
        disptach(GetImageParCommand(id));
      }
    });
  }
  console.log(images);
  return (
    <>
      <Dialog open={open} fullScreen>
        <DialogTitle>
          <Typography variant="h4" textAlign="center" gutterBottom>
            image traite
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {AllImages?.map((image) => (
              <Grid item xs={3}>
                <Card>
                  <CardMedia
                    component="img"
                    image={`${
                      image.ImageTraites[0].url
                    }?v=${new Date().getTime()}`}
                  />
                  <IconButton
                    onClick={() => {
                      setIdImage(image.id);
                      setSrc(image.url);
                      setDemonsion(
                        imprimant?.demontions?.find(
                          (d) => image.idProduit == d.idProduit
                        )
                      );
                      setOpenCropper(true);
                    }}
                  >
                    <CropIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      downloadImage(image.ImageTraites[0].url, image.id);
                    }}
                  >
                    <ArrowCircleDownIcon />
                  </IconButton>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Annulé</Button>
        </DialogActions>
      </Dialog>
      <ImageCropDialog
        open={openCropper}
        onClose={() => {
          setOpenCropper(false);
        }}
        src={src}
        demonsion={demonsion}
        editeImage={editeImage}
      />
    </>
  );
}

function ImageCropDialog({ open, onClose, src, demonsion, editeImage }) {
  const [crop, setCrop] = useState({
    unit: "%",
    height: 100,
    aspect: demonsion?.typeCroperW / demonsion?.typeCroperH,
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <div style={{ width: "50%", margin: "auto" }}>
          <ReactCrop
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            aspect={demonsion?.typeCroperW / demonsion?.typeCroperH}
          >
            <img
              src={src}
              alt="Crop me"
              style={{ maxWidth: "auto", height: "400px" }}
            />
          </ReactCrop>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button onClick={() => editeImage(crop)}>Recadrer</Button>
      </DialogActions>
    </Dialog>
  );
}
